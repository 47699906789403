import { useRef } from 'react';

import { getStaticAssetPath } from '../../utils/assets';

export type OrbProps = {
  state: 'still' | 'listening' | 'talking' | 'processing';
  styles: Partial<{
    size: string;
  }>;
};

const assetSources = {
  still: getStaticAssetPath('images/collective/orb-still.png'),
  listening: getStaticAssetPath('videos/collective/orb-listening.mp4'),
  talking: getStaticAssetPath('videos/collective/orb-talking.mp4'),
  processing: getStaticAssetPath('videos/collective/orb-processing.mp4'),
};

export function Orb(props: OrbProps): JSX.Element {
  const { state, styles } = props;
  const videoRef = useRef<HTMLVideoElement>(null);

  const body =
    state === 'still' ? (
      <img
        src={assetSources[state]}
        alt='orb'
        className='w-full h-full object-cover'
      />
    ) : (
      <video
        ref={videoRef}
        src={assetSources[state]}
        autoPlay
        loop
        muted
        className='w-full h-full object-cover'
      />
    );

  return (
    <div
      className={`
        ${styles.size || 'w-30 h-30'}
      `}
    >
      {body}
    </div>
  );
}
