import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useSearchParams,
} from '@remix-run/react';
import { Widget } from '@typeform/embed-react';
import React, {
  type CSSProperties,
  Fragment,
  type ReactNode,
  Suspense,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { useEffectOnce } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import {
  type DtoInterview,
  type DtoThoughtStarter,
  EnumsInterviewStatus,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { useDeviceCheck } from '../components/Collective/MediaDeviceControl';
import { Orb, type OrbProps } from '../components/Collective/Orb';
import { CollectiveProviders } from '../components/Collective/Providers';
import { log, markdownComponents } from '../components/Collective/utils';
import { Inquirer, WelcomePage } from '../components/Collective/Welcome';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../components/ConfirmCancelModalContext';
import {
  DeviceContextProvider,
  getUserMedia,
  useDeviceState,
} from '../components/Device';
import { MicVolumeMeterVisualizer } from '../components/Device/MicVolumeMeterVisualizer';
import { useCreateDefaultVideoStreamMixer } from '../components/Device/video-stream-mixer';
import { GlobalLoading } from '../components/GlobalLoading';
import { ArrowRightIcon } from '../components/icons/Arrows';
import { LPLogo } from '../components/icons/LPLogo';
import { MicrophoneIcon } from '../components/icons/MicrophoneIcon';
import { ThoughtStarterIcon } from '../components/icons/ThoughtStarterIcon';
import { Loading } from '../components/Loading';
import { ProvidersList } from '../components/ProvidersList';
import config from '../config';
import { useInterval } from '../hooks/useInterval';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { apiService } from '../services/api-service';
import { getStaticAssetPath } from '../utils/assets';
import { assertExhaustive } from '../utils/common';
import { playWithCatch } from '../utils/playWithCatch';
import { tokenWithRedirect } from '../utils/router';
import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../utils/valtio';

const genericWelcome = getStaticAssetPath(
  'audios/collective/generic-welcome.mp3'
);

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw new Error('expected inquiry id');
  }

  const resp = await tokenWithRedirect(
    () => apiService.collective.getInterview(id),
    action.request.url
  );

  return { data: resp.data };
};

function Interviews() {
  const mixer = useCreateDefaultVideoStreamMixer();
  const providers = [
    <CollectiveProviders />,
    <ConfirmCancelModalProvider />,
    <DeviceContextProvider profileIndex='720p' mixer={mixer} />,
    <Provider />,
  ];
  return (
    <Suspense fallback={<GlobalLoading />}>
      <ProvidersList providers={providers}>
        <Fragment>
          <Interview />
          <ConfirmCancelModalRoot />
        </Fragment>
      </ProvidersList>
    </Suspense>
  );
}

export const Component = Interviews;

function Interview(): JSX.Element | null {
  const api = useInterviewApi();
  // NOTE(jialin): we avoid the destructuring here because of the valtio eslint
  // Better to just use proxy state.eslint(valtio/state-snapshot-rule)
  const interview = (useSnapshot(api.state) as typeof api.state).interview;
  const state = useSnapshot(api.state).state;
  const deviceChecked = useSnapshot(api.state).deviceChecked;
  const { activeAudioInputDeviceOption } = useDeviceState();
  const doDeviceCheck = useDeviceCheck(deviceChecked, () =>
    api.setDeviceChecked()
  );

  const handleStart = useLiveCallback(async () => {
    const result = await doDeviceCheck();
    if (result.result === 'canceled') return;
    await api.welcome();
  });

  // If users come back from a interrupted interview, it starts directly with
  // answering phase. We still need to do the device check first.
  const handleStartAnswer = async () => {
    const result = await doDeviceCheck();
    if (result.result === 'canceled') return;
    await api.answerQuestion(activeAudioInputDeviceOption?.value);
  };

  // this handles the case where the user navigates to the interview page from
  // `/inquiries/{id}/interview`， which set the default state to _welcoming_
  // without triggering _handleStart_.
  useLayoutEffect(() => {
    if (state === 'welcoming' && !deviceChecked) {
      handleStart();
    }
  }, [api, deviceChecked, handleStart, state]);

  switch (interview.status) {
    case EnumsInterviewStatus.InterviewStatusNotStarted:
      return (
        <>
          {state === 'welcoming' && deviceChecked && <IntroductionModal />}
          <WelcomePage inquiry={interview.inquiry} onStart={handleStart} />
        </>
      );
    case EnumsInterviewStatus.InterviewStatusInProgress:
      return <InProgress onStartAnswer={handleStartAnswer} />;
    case EnumsInterviewStatus.InterviewStatusSummarizing:
      return <Summarizing />;
    case EnumsInterviewStatus.InterviewStatusInReview:
      return <ReviewSummary />;
    case EnumsInterviewStatus.InterviewStatusSubmitted:
      return <Submitted />;
    default:
      assertExhaustive(interview.status);
      return null;
  }
}

type InterviewState = {
  interview: DtoInterview;
  orb: OrbProps['state'];
  state: 'none' | 'welcoming' | 'answering' | 'submitting';
  questionIndex: number;
  deviceChecked: boolean;
  uploadError?: unknown;
};

class InterviewApi {
  constructor(
    private readonly _state: ValtioSnapshottable<InterviewState>,
    private _mediaRecorder?: MediaRecorder,
    private chunks: Blob[] = []
  ) {}

  get state() {
    return this._state;
  }

  get mediaRecorder() {
    return this._mediaRecorder;
  }

  private async playAudio(srcs: string[]) {
    this._state.orb = 'talking';
    try {
      for (const src of srcs) {
        await new Promise<void>((resolve, reject) => {
          const audio = new Audio(src);
          audio.addEventListener('ended', () => resolve(), { once: true });
          audio.addEventListener('error', (err) => reject(err), { once: true });
          playWithCatch(audio);
        });
      }
    } finally {
      if (this._state.orb === 'talking') {
        this._state.orb = 'still';
      }
    }
  }

  async welcome() {
    this._state.state = 'welcoming';
  }

  async startInterview() {
    const resp = await apiService.collective.startInterview(
      this._state.interview.id
    );
    this._state.state = 'none';
    this._state.interview = resp.data.interview;
  }

  async playWelcomeAudio(): Promise<void> {
    let welcomeAudio;
    try {
      const r = await apiService.collective.renderInterviewWelcome(
        this._state.interview.id
      );
      const data = r.data;
      welcomeAudio = URL.createObjectURL(data);
    } catch (e) {
      welcomeAudio = genericWelcome;
    }
    await this.playAudio([welcomeAudio]);
  }

  async playQuestionAudio(): Promise<void> {
    if (
      this._state.questionIndex < 0 ||
      this._state.questionIndex >= this._state.interview.questions.length
    ) {
      return;
    }

    const maybeMediaUrl =
      this._state.interview.questions[this._state.questionIndex].ttsMedia?.url;
    if (!maybeMediaUrl) return;

    await this.playAudio([maybeMediaUrl]);
  }

  async answerQuestion(audioDeviceId?: string): Promise<void> {
    this.chunks = [];
    const stream = await getUserMedia({
      audio: audioDeviceId ? { deviceId: audioDeviceId } : true,
    });
    this._mediaRecorder = new MediaRecorder(stream);

    this._mediaRecorder.addEventListener(
      'dataavailable',
      (event: BlobEvent) => {
        this.chunks.push(event.data);
      }
    );

    this._state.state = 'answering';
    this._state.orb = 'listening';
    this._mediaRecorder.start();
  }

  private async uploadChunks() {
    if (this._state.uploadError) {
      this._state.uploadError = undefined;
    }

    const audioBlob = new Blob(this.chunks);
    const response = await apiService.media.upload(audioBlob, {
      // TODO(falcon): this is hardcoded for now, but it's inconsistent with the actual encoding of the blob.
      // We should adopt something similar to the IDBMediaRecorder to detect what the browser actually encodes,
      // and that content type should be reflected here. This is currently wrong, but it works in some cases.
      // However, it's leading to inconsistent data in the backend.
      contentType: 'audio/wav',
      scene: 'collective',
    });
    this.chunks = [];
    return response.data.media;
  }

  async nextQuestion(): Promise<void> {
    const mediaRecorder = this._mediaRecorder;
    if (!mediaRecorder) return;

    this._state.state = 'submitting';
    this._state.orb = 'processing';

    let uploadPromise: Promise<Media>;
    if (this._state.uploadError && this.chunks) {
      uploadPromise = this.uploadChunks();
    } else {
      uploadPromise = new Promise<Media>((resolve, reject) => {
        mediaRecorder.addEventListener('stop', async () => {
          try {
            const result = this.uploadChunks();
            resolve(result);
          } catch (e) {
            reject(e);
          }
        });
      });

      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      mediaRecorder.stop();
    }

    let uploadedMedia;
    try {
      uploadedMedia = await uploadPromise;
    } catch (e) {
      log.error('failed to upload media', e);
      this._state.uploadError = e;
      return;
    }

    const resp = await apiService.collective.createQuestionReply({
      interviewId: this._state.interview.id,
      questionId: this._state.interview.questions[this._state.questionIndex].id,
      replyMediaId: uploadedMedia.id,
    });
    this._state.interview = resp.data.interview;

    const isLastQuestion =
      this._state.questionIndex + 1 >= this._state.interview.questions.length;

    if (isLastQuestion) {
      const resp = await apiService.collective.summarizeInterview(
        this._state.interview.id
      );
      this._state.interview = resp.data.interview;
    }

    ValtioUtils.update(this._state, {
      state: 'none',
      orb: 'still',
      questionIndex: isLastQuestion
        ? this._state.questionIndex
        : this._state.questionIndex + 1,
    });
  }

  async revalidate() {
    const resp = await apiService.collective.getInterview(
      this._state.interview.id
    );
    this._state.interview = resp.data.interview;
  }

  async updateInterviewSummary(summary: string) {
    const resp = await apiService.collective.updateInterview({
      interviewId: this._state.interview.id,
      editedSummary: summary,
    });
    this._state.interview = resp.data.interview;
  }

  async submitInterview() {
    const resp = await apiService.collective.submitInterview(
      this._state.interview.id
    );
    this._state.interview = resp.data.interview;
  }

  setDeviceChecked() {
    this._state.deviceChecked = true;
  }
}

const context = React.createContext<InterviewApi | null>(null);

function Provider(props: { children?: ReactNode }): JSX.Element {
  const {
    data: { interview },
  } = useLoaderData<typeof clientLoader>();

  const [searchParams] = useSearchParams();
  const shouldWelcome = searchParams.get('welcome') === 'true';
  const defaultState =
    interview.status === EnumsInterviewStatus.InterviewStatusNotStarted &&
    shouldWelcome
      ? 'welcoming'
      : 'none';

  // find the last question that has a reply
  const replies = interview.questionReplies ?? [];
  const lastQuestionIndex = replies.reduce((acc, reply) => {
    return Math.max(
      acc,
      interview.questions.findIndex((q) => q.id === reply.questionId)
    );
  }, -1);

  const questionIndex = Math.min(
    lastQuestionIndex + 1,
    interview.questions.length - 1
  );

  const state = useRef(
    markSnapshottable(
      proxy({
        interview,
        orb: 'still',
        state: defaultState,
        questionIndex,
        deviceChecked: false,
      } as InterviewState)
    )
  );

  const api = useMemo(() => new InterviewApi(state.current), []);
  return <context.Provider value={api}>{props.children}</context.Provider>;
}

function useInterviewApi(): InterviewApi {
  const ctx = useContext(context);
  if (!ctx) throw new Error('InterviewContext is not in the tree!');
  return ctx;
}

function Layout(props: { children: React.ReactNode }): JSX.Element {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const api = useInterviewApi();
  const { interview, uploadError } = useSnapshot(api.state);
  const { inquiry } = interview;

  useEffect(() => {
    if (!api.state.uploadError) return;

    async function run() {
      await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='px-5'>
            <ConfirmCancelModalHeading>
              Something went wrong
            </ConfirmCancelModalHeading>
            <ConfirmCancelModalText className='mt-2 text-sm font-normal text-icon-gray'>
              There was an error uploading your audio. Please try again.
            </ConfirmCancelModalText>
          </div>
        ),
        confirmBtnLabel: 'Upload',
        confirmBtnVariant: 'delete',
        confirmOnly: true,
      });
      api.nextQuestion();
    }
    run();
  }, [api, uploadError, triggerModal]);

  return (
    <div className='w-full h-full text-white flex flex-col justify-between min-h-0 bg-layer-001'>
      <header className='hidden w-full md:flex items-center pl-2.5 pt-2.5 pr-5 justify-between'>
        <LPLogo />
        <Inquirer uid={inquiry.uid} name={inquiry.inquirerName} />
      </header>

      <div className='md:hidden pt-12 flex flex-col items-center justify-center gap-6'>
        <Inquirer uid={inquiry.uid} name={inquiry.inquirerName} />
      </div>

      {props.children}
    </div>
  );
}

function Footer(props: { children: React.ReactNode }): JSX.Element {
  return (
    <footer className='px-9.5 pb-12 md:pb-18 flex flex-col items-center gap-5'>
      {props.children}
    </footer>
  );
}

function InProgress(props: {
  onStartAnswer: () => Promise<void>;
}): JSX.Element {
  const api = useInterviewApi();
  const { interview, orb, state, questionIndex } = useSnapshot(api.state);
  const { questions } = interview;
  const numQuestions = questions.length;
  const thoughtStarters = questions[questionIndex].thoughtStarters;

  const [selectedThoughtStarter, setSelectedThoughtStarter] = useState<
    DtoThoughtStarter | undefined
  >(undefined);

  useEffect(() => {
    api.playQuestionAudio();
  }, [questionIndex, api]);

  const thoughtStarterElements = useMemo(() => {
    return (thoughtStarters ?? []).map((ts) => (
      <div
        key={ts.id}
        className='p-3 pr-2 border border-secondary rounded-lg flex items-center cursor-pointer'
        onClick={() => setSelectedThoughtStarter(ts)}
      >
        <div className='flex-1 text-sms line-clamp-6'>{ts.text}</div>
        <ArrowRightIcon />
      </div>
    ));
  }, [thoughtStarters]);

  const handleNext = () => {
    if (state === 'none') {
      props.onStartAnswer();
    } else if (state === 'answering') {
      api.nextQuestion();
    }
  };

  const handleClose = () => {
    setSelectedThoughtStarter(undefined);
  };

  return (
    <Layout>
      {selectedThoughtStarter && (
        <ThoughtStarterModal
          thoughtStarter={selectedThoughtStarter}
          onClose={handleClose}
        />
      )}
      <main className='relative flex-1 flex justify-center min-h-0 mt-7 mb-3 animate-fade-in'>
        <div className='flex-1 w-full lg:max-w-160 flex flex-col items-center gap-2.5 min-h-0'>
          <Orb state={orb} styles={{ size: 'w-30 h-30 md:w-35 md:h-35' }} />
          {orb === 'listening' && api.mediaRecorder && (
            <div className='flex flex-col items-center justify-center gap-1'>
              <div className='text-[#FF0935] text-sm font-bold'>
                Listening...
              </div>
              <MicVolumeMeterVisualizer
                stream={api.mediaRecorder.stream}
                fps={30}
                segmentSize='w-4 h-1'
                className='gap-0'
              />
            </div>
          )}
          {state !== 'submitting' && (
            <>
              <div className='italic text-icon-gray font-bold text-center'>
                Question {questionIndex + 1} of {numQuestions}
              </div>
              <div className='px-5'>
                <p
                  className={`
                    text-center
                    font-medium text-1.5xl 
                    md:font-light md:text-3.5xl md:leading-normal
                  `}
                >
                  {questions[questionIndex].text}
                </p>
              </div>
              {thoughtStarterElements.length > 0 && (
                <div
                  className={`
                  min-h-0 relative
                  flex-1 w-full px-5 flex flex-col
                  xl:absolute xl:right-5 xl:w-76 xl:px-0
                `}
                >
                  <div className='text-tertiary text-2xs font-bold pb-2.5 flex items-center gap-1'>
                    <ThoughtStarterIcon className='w-4.5 h-4.5 fill-current' />
                    Thought Starters
                  </div>
                  <div className='space-y-2.5 pr-2 overflow-auto scrollbar pb-5 xl:pb-0'>
                    {thoughtStarterElements}
                  </div>
                  <div
                    className='absolute bottom-0 left-0 right-0 h-5 xl:hidden'
                    style={{
                      background:
                        'linear-gradient(180deg, rgba(16, 16, 18, 0.00) 0%, #101012 100%)',
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </main>

      <Footer>
        {state === 'none' ? (
          <div className='w-full md:max-w-75 h-16 relative'>
            <div
              className={`absolute bg-[#FF0935] inset-0 rounded-xl transform animate-plusing`}
              style={
                {
                  '--tw-plusing-duration': '2s',
                } as CSSProperties
              }
            />
            <button
              type='button'
              className={`
              btn absolute inset-0
              flex items-center justify-center gap-1
              bg-[#FF0935] hover:bg-[#FF2D53]
              transition-colors
              font-bold text-center tracking-wide
            `}
              onClick={handleNext}
            >
              <MicrophoneIcon />
              Answer Question
            </button>
          </div>
        ) : (
          <>
            <button
              type='button'
              className={`
                btn w-full md:max-w-75 h-16
                flex items-center justify-center gap-1
                bg-secondary hover:bg-secondary-hover
                transition-colors
                font-bold text-center tracking-wide
              `}
              disabled={state === 'submitting'}
              onClick={handleNext}
            >
              {state === 'answering' ? (
                questionIndex === numQuestions - 1 ? (
                  <>End &amp; Review Summary</>
                ) : (
                  <>Next Question</>
                )
              ) : state === 'submitting' ? (
                <>Uploading...</>
              ) : null}
            </button>
          </>
        )}
        <div className='h-9'>
          {state === 'none' && (
            <div className='text-sms text-center text-icon-gray'>
              Don’t sweat the exact words.
              <br />
              We’ll summarize everything for your review.
            </div>
          )}
        </div>
      </Footer>
    </Layout>
  );
}

function Summarizing(): JSX.Element {
  const api = useInterviewApi();
  const { interview } = useSnapshot(api.state);

  useInterval(
    () => api.revalidate(),
    interview.status === EnumsInterviewStatus.InterviewStatusSummarizing
      ? 1000
      : null
  );

  return (
    <Layout>
      <main className='relative flex-1 flex justify-center min-h-0 mt-7 mb-3'>
        <div className='flex-1 w-full lg:max-w-160 flex flex-col items-center gap-2.5 min-h-0'>
          <Orb
            state='processing'
            styles={{ size: 'w-30 h-30 md:w-35 md:h-35' }}
          />
        </div>
      </main>

      <Footer>
        <button
          type='button'
          className={`
            btn w-full h-16 md:max-w-75
            flex items-center justify-center gap-1
            bg-secondary hover:bg-secondary-hover transition-colors
            font-bold text-center tracking-wide
          `}
          disabled
        >
          Summarizing...
        </button>
        <div className='h-9' />
      </Footer>
    </Layout>
  );
}

function ReviewSummary(): JSX.Element {
  const api = useInterviewApi();
  const { interview } = useSnapshot(api.state);
  const { inquiry } = interview;

  const [isEditing, setIsEditing] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm<{ summary: string }>({
    mode: 'onChange',
    defaultValues: {
      summary: interview.editedSummary || interview.generatedSummary,
    },
  });

  const handleCancel = () => {
    reset({ summary: interview.editedSummary || interview.generatedSummary });
    setIsEditing(false);
  };

  const handleSubmitClick = async () => {
    await handleSubmit(async ({ summary }) => {
      await api.updateInterviewSummary(summary);
      reset({ summary });
      setIsEditing(false);
    })();
  };

  const handleConfirmAndSubmit = async () => {
    // note: cheeky way to reuse the isSubmitting flag.
    await handleSubmit(async () => {
      await api.submitInterview();
    })();
  };

  const replies = [...(interview.questionReplies ?? [])];
  replies.sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });

  const fullTranscript = replies.map((qr) => qr.transcript).join('\n\n');

  return (
    <Layout>
      <main className='relative flex-1 flex justify-center min-h-0 mt-7 mb-3'>
        <div className='flex-1 w-full flex flex-col items-center gap-2.5 min-h-0'>
          <div className='px-5 text-xl md:text-3.5xl text-center font-bold'>
            Review Final Summary
          </div>
          <div className='px-5 pb-3 w-full lg:w-3/4 pt-6 text-sms md:text-base text-center font-bold'>
            {inquiry.query}
          </div>

          <div className='w-full px-5 md:px-12 lg:px-24 flex-1 flex justify-center gap-5 min-h-0'>
            <div className='hidden flex-none md:flex flex-col gap-2.5 w-1/2'>
              <div className='text-sms text-icon-gray'>
                This is your transcript.
              </div>
              <div className='field h-full py-2 px-2.5 mb-0 scrollbar overflow-auto text-sms text-icon-gray hover:border-secondary focus:border-secondary whitespace-pre-wrap'>
                {fullTranscript}
              </div>
            </div>

            <div className='flex-none flex flex-col gap-2.5 w-full md:w-1/2'>
              <div className='flex flex-col md:flex-row items-center justify-between gap-2'>
                <div className='text-sms text-icon-gray truncate'>
                  This is your summary. This text will be sent to{' '}
                  {inquiry.inquirerName}.
                </div>
              </div>

              {isEditing ? (
                <textarea
                  className='flex w-full h-full py-2 px-2.5 resize-none mb-0 scrollbar field text-sms'
                  readOnly={!isEditing}
                  {...register('summary', {
                    required: true,
                  })}
                />
              ) : (
                <Controller<{ summary: string }>
                  control={control}
                  name='summary'
                  render={({ field: { value } }) => (
                    <div className='field h-full py-2 px-2.5 mb-0 scrollbar overflow-auto text-sms hover:border-secondary focus:border-secondary whitespace-pre-wrap'>
                      <ReactMarkdown components={markdownComponents}>
                        {value}
                      </ReactMarkdown>
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </main>
      <footer className='px-9.5 pb-12 md:pb-18 flex flex-col items-center gap-5'>
        <div className='w-full flex flex-col-reverse md:flex-row items-center justify-center gap-5'>
          {isEditing ? (
            <>
              <button
                key='cancel'
                type='button'
                className={`
                  btn w-full h-16 md:max-w-75
                  flex items-center justify-center gap-1
                  bg-secondary hover:bg-secondary-hover transition-colors
                  font-bold text-center tracking-wide
                `}
                disabled={isSubmitting}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type='button'
                className={`
                  btn w-full h-16 md:max-w-75
                  flex items-center justify-center gap-1
                  bg-[#FF0935] hover:bg-[#FF2D53] transition-colors
                  font-bold text-center tracking-wide
                `}
                disabled={!isDirty || isSubmitting}
                onClick={handleSubmitClick}
              >
                {isSubmitting ? <Loading text='' /> : 'Save Changes'}
              </button>
            </>
          ) : (
            <button
              type='button'
              className={`
                btn w-full h-16 md:max-w-75
                flex items-center justify-center gap-1
                bg-[#FF0935] hover:bg-[#FF2D53] transition-colors
                font-bold text-center tracking-wide
              `}
              disabled={isSubmitting}
              onClick={handleConfirmAndSubmit}
            >
              {isSubmitting ? <Loading text='' /> : 'Confirm & Submit'}
            </button>
          )}
        </div>

        {!isEditing && (
          <div className='h-3 flex flex-col items-center justify-between'>
            <button
              type='button'
              className='btn text-sms text-center text-tertiary font-bold'
              onClick={() => setIsEditing(true)}
            >
              Edit Summary
            </button>
          </div>
        )}
      </footer>
    </Layout>
  );
}

function Submitted(): JSX.Element {
  return (
    <Layout>
      <main className='relative flex-1 flex justify-center min-h-0 mt-7 mb-3'>
        <div className='flex-1 w-full lg:max-w-[900px] flex flex-col items-center gap-2.5 min-h-0'>
          <div className='px-5 pb-3'>
            <div className='text-xl md:text-3.5xl text-center font-bold'>
              Submission Successful! 🎉
            </div>
          </div>
          <div className='p-4 w-full h-full'>
            <Widget
              id={config.misc.typeFormIdCollective}
              className='w-full h-full'
              inlineOnMobile
            />
          </div>
        </div>
      </main>
    </Layout>
  );
}

function ThoughtStarterModal(props: {
  thoughtStarter: DtoThoughtStarter;
  onClose: () => void;
}): JSX.Element {
  const api = useInterviewApi();
  const { orb } = useSnapshot(api.state);

  return (
    <div className='fixed inset-0 bg-black bg-opacity-80 z-50 min-h-0'>
      <div className='w-full md:max-w-208 md:mx-auto h-full flex flex-col items-center justify-center gap-4 pt-15 pb-4 px-2.5 min-h-0'>
        <Orb
          state={orb}
          styles={{
            size: 'flex-none w-[65px] h-[65px] md:w-[77px] md:h-[77px]',
          }}
        />
        <div className='px-4 pt-4 pb-5 w-full bg-layer-001 border border-secondary rounded-xl flex flex-col items-center gap-2.5 min-h-0'>
          <div className='w-full text-tertiary text-2xs font-bold flex items-center gap-1'>
            <ThoughtStarterIcon className='w-4.5 h-4.5 fill-current' />
            Thought Starters
          </div>
          <div className='w-full pr-2 overflow-y-scroll scrollbar text-sms'>
            {props.thoughtStarter.text}
          </div>
          <button
            type='button'
            className={`
              flex-none
              btn w-full h-16 md:max-w-75
              flex items-center justify-center gap-1
              bg-secondary hover:bg-secondary-hover transition-colors
              font-bold text-center tracking-wide
            `}
            onClick={props.onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

function IntroductionModal(): JSX.Element {
  const api = useInterviewApi();
  const { orb } = useSnapshot(api.state);

  useEffectOnce(() => {
    async function run() {
      await api.playWelcomeAudio();
      await api.startInterview();
    }
    run();
  });

  return (
    <div className='fixed inset-0 bg-black bg-opacity-80 z-50 min-h-0 animate-fade-in'>
      <div className='w-full md:max-w-208 md:mx-auto h-full flex flex-col items-center justify-center gap-4 pt-15 pb-4 px-2.5 min-h-0'>
        <Orb
          state={orb}
          styles={{
            size: 'flex-none w-[65px] h-[65px] md:w-[77px] md:h-[77px]',
          }}
        />
      </div>
    </div>
  );
}
