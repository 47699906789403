import { useEffect, useRef, useState } from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useStatsAwareTaskQueue } from '../../hooks/useTaskQueue';
import { releaseMediaStream } from '../../utils/media';
import {
  MicVolumeMeter,
  MicVolumeMeterProcessor,
} from '../../utils/MicVolumeMeter';
import { isMobile } from '../../utils/user-agent';
import {
  type ConfirmCancelModalConfigurator,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { getUserMedia, useDeviceState } from '../Device';
import {
  DeviceCheckContextProvider,
  DeviceSelector,
  useDeviceLoaded,
} from '../Device/Check';
import { Loading } from '../Loading';
import { MicrophoneWithVolumeBar } from '../MediaControls';

export function MediaDeviceControl(): JSX.Element {
  const { activeAudioInputDeviceOption } = useDeviceState();
  const ref = useRef(null);
  const [processor, setProcessor] = useState<
    { instance: MicVolumeMeterProcessor; stream: MediaStream } | undefined
  >(undefined);
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-music-device-control-ms',
  });

  const releaseMeter = useLiveCallback(() => {
    processor?.instance.close();
    releaseMediaStream(processor?.stream);
  });

  const updateMeter = useLiveCallback(async (deviceId?: string) => {
    releaseMeter();
    const stream = await getUserMedia({ audio: { deviceId } });
    setProcessor({
      stream,
      instance: new MicVolumeMeterProcessor(new MicVolumeMeter(stream)),
    });
  });

  useEffect(() => {
    addTask(async function run() {
      updateMeter(activeAudioInputDeviceOption?.value);
    });
  }, [activeAudioInputDeviceOption?.value, addTask, updateMeter]);

  useEffect(() => {
    return () => releaseMeter();
  }, [releaseMeter]);

  return (
    <div ref={ref} className='hidden md:flex absolute bottom-0 left-0 m-2 z-45'>
      <button
        type='button'
        className={`icon-btn relative`}
        data-testid='toggle-audio'
        disabled
      >
        <MicrophoneWithVolumeBar processor={processor?.instance} />
      </button>
    </div>
  );
}

function DeviceSelectorContainer(props: { onLoaded?: () => void }) {
  useDeviceLoaded(props.onLoaded);
  return (
    <DeviceSelector
      width='w-full'
      cameraSelectEnabled={false}
      cameraSelectVisible={false}
    />
  );
}

export function DeviceCheckModal(props: {
  onConfirm: () => void;
  onCancel: () => void;
}): JSX.Element {
  const [loaded, setLoaded] = useState(false);

  const handleContinue = () => {
    props.onConfirm();
  };

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <ModalWrapper containerClassName='max-w-125' borderStyle='gray'>
      <div className='p-10 flex flex-col items-center justify-center gap-2 bg-[#101012] rounded-xl'>
        <header className='text-center font-bold mb-8'>
          Welcome! Check your settings before you start.
          <div className='font-normal text-sm text-icon-gray mt-1'>
            This experience plays audio. We recommend using headphones.
          </div>
        </header>
        <DeviceCheckContextProvider>
          <DeviceSelectorContainer onLoaded={() => setLoaded(true)} />
        </DeviceCheckContextProvider>
        <footer className='flex items-center gap-4'>
          <button
            type='button'
            className={`
              btn w-50 h-12.5
              flex items-center justify-center
              bg-secondary hover:bg-secondary-hover transition-colors
              font-bold text-center tracking-wide
            `}
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className={`
              btn w-50 h-12.5
              flex items-center justify-center
              bg-[#FF0935] hover:bg-[#FF2D53] transition-colors
              font-bold text-center tracking-wide
            `}
            onClick={handleContinue}
            disabled={!loaded}
          >
            {!loaded && <Loading text='' containerClassName='mr-2' />}
            <div>{loaded ? 'Continue' : 'Loading'}</div>
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export type DeviceCheckResult = ReturnType<ConfirmCancelModalConfigurator>;

export function useDeviceCheck(
  checked: boolean,
  setChecked: () => void
): () => DeviceCheckResult {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback(async () => {
    if (checked || isMobile()) return Promise.resolve({ result: 'confirmed' });
    return await triggerModal({
      kind: 'custom',
      element: (p) => (
        <DeviceCheckModal
          onConfirm={() => {
            p.internalOnConfirm();
            setChecked();
          }}
          onCancel={p.internalOnCancel}
        />
      ),
    });
  });
}
